import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import { BiWorld, BiSearchAlt } from "react-icons/bi"

import {
  BsFillBarChartFill,
  BsFillDashCircleFill,
  BsFillLightningFill,
} from "react-icons/bs"

import { ImAirplane } from "react-icons/im"

import { MdLocalShipping } from "react-icons/md"

import { VscFeedback } from "react-icons/vsc"

import { HiTemplate } from "react-icons/hi"

const Features = () => {
  return (
    <Container fluid className="px-0">
      <div className="text-center" style={{ position: "relative" }}>
        <Styledh1 className="display-4">FBA Seller App Features</Styledh1>
        <StaticImage
          src="../../assets/images/features.png"
          alt="Amazon FBA App Features"
          placeholder="tracedSVG"
          layout="fullWidth"
          aspectRatio={6 / 1}
          style={{ zIndex: "-1" }}
        ></StaticImage>
      </div>
      <Container className="p-5">
        <Row className="mx-auto">
          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/All_Amazon_Marketplaces_In_One_Dashboard">
              <BoxStyle>
                <BiWorld style={{ fontSize: "xxx-large", color: "#4f91f3" }} />
                <Styledh5>All Amazon Marketplaces In One Dashboard</Styledh5>
                <p>
                  How much time do you spend navigating through Amazon
                  marketplaces just to see sales, feedbacks, shipments, etc..?
                  azszero makes it easier to see everything in one dashboard and
                  provides direct links to any actions needed to be taken.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>
          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/In_Depth_Sales">
              <BoxStyle>
                <BsFillBarChartFill
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>In Depth Sales Dashboard</Styledh5>
                <p>
                  See a list of the items sold today from all marketplaces with
                  quantity sold in real-time and many sophisticated sales
                  reports.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>
          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/ASINs_Performance">
              <BoxStyle>
                <BiSearchAlt
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>ASINs Performance</Styledh5>
                <p>
                  Get notified if an item is suppressed from the search
                  immediately, also a graph of its sales rank performance over
                  time.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>

          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/Refund_Alerts_and_Customer_Comments">
              <BoxStyle>
                <BsFillDashCircleFill
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>Refund Alerts and Customer Comments</Styledh5>
                <p>
                  Get alerts on items that could be suspended for high return
                  rates, and know why by reading what the users are saying about
                  your product.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>

          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/FBA_Inbounds">
              <BoxStyle>
                <ImAirplane
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>FBA Inbounds</Styledh5>
                <p>
                  Track all your FBA shipments from the date you created them
                  until they are fully received and get notifications if there
                  are any problems while shipments are in transit or at Amazon
                  warehouses.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>

          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/Inventory_Management">
              <BoxStyle>
                <MdLocalShipping
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>Inventory Management</Styledh5>
                <p>
                  Manage all your products for all marketplaces knowing how many
                  items to order, when to prepare, and how many to ship.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>

          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/Feedback">
              <BoxStyle>
                <VscFeedback
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>Feedback</Styledh5>
                <p>
                  Know your customer's feedback on all the markets and try to
                  resolve them.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>

          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/Actions">
              <BoxStyle>
                <BsFillLightningFill
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>Actions</Styledh5>
                <p>
                  Test multiple selling or advertisement strategies and get the
                  result showing how each strategy performed before, during, and
                  after the strategy was applied.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>

          <Col sm={12} md={6} lg={4} className="pb-5">
            <StyledLink to="/features/Page_Builder">
              <BoxStyle>
                <HiTemplate
                  style={{ fontSize: "xxx-large", color: "#4f91f3" }}
                />
                <Styledh5>Page Builder</Styledh5>
                <p>
                  Build the most comprehensive SEO-ready product page harvesting
                  and suggesting keywords to you with compliance to amazon
                  product page templates.
                </p>
              </BoxStyle>
            </StyledLink>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

const BoxStyle = styled.div`
  border-color: #b5cfff;
  box-shadow: 3px 3px 12px rgb(0 0 0 / 12%);
  border-bottom-style: solid;
  background: transparent;
  text-align: center;
  padding: 20px;
  height: 100%;
  :hover {
    background-color: aliceblue;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

const Styledh5 = styled.h5`
  padding-top: 20px;
  padding-bottom: 15px;
`
const Styledh1 = styled.h1`
  padding: 30px;
  color: slategray;
  margin-bottom: -50px;
  z-index: 10;
  font-weight: 700;
`
export default Features
